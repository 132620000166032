.header {
    background-color: var(--blue);
    border-radius: 8px 8px 0 0;
}

.hintIcon {
    position: absolute;
    top: -25%;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: var(--red);
}

.name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 480px) {
    .header {
        border-radius: 0;
    }
}