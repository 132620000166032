.container {
    margin-top: 27%;
}

/*.noteBox {
    !*background-color: var(--darkRed);*!
    border: 1px solid var(--redAlphaGradient);
}*/

.Blocked {
    /*background-color: var(--blackRed);*/
    border: 1px solid var(--blackRed);
    /*color: var(--white);*/
}

.Rejected {
    /*background-color: var(--blackRed);*/
    border: 1px solid var(--darkRed);
    /*color: var(--white);*/
}

.NoKYC {
    /*background-color: var(--orange);*/
    /*color: var(--orange);*/
    border: 1px solid var(--gray);
}
.Pending {
    /*background-color: var(--orange);*/
    /*color: var(--orange);*/
    border: 1px solid var(--blackOrange);
}

.Approved {
    /*background-color: var(--blackGreen);*/
    border: 1px solid var(--darkGreen);
    /*color: var(--white);*/
}

.rectangle2 {
    height: 5.85vh;
    background-color: var(--cardHeader);
}

@media (max-width: 480px) {


    .rectangle2 {
        height: 6.5vh;
        background-color: var(--cardHeader);
    }
}
