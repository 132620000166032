.amountBox {
    background-color: var(--lightBlue);
}
.space {
    margin: 0 0.5vw 0 0;
}
:global(.ltr) .space {
    margin: 0 0 0 0.5vw;
}
.loading {
    height: 5vh;
    background-color: var(--cardHeader);
}


.asset {
    border: 1px solid #E0E0E0;
    margin: 2vh auto;
}

.logo {
    width: 3.1vw;
    height: 3.1vw;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}


@media (max-width: 480px) {

    .space {
        margin: 0 1vw 0 0;
    }
    :global(.ltr) .space {
        margin: 0 0 0 1vw;
    }

    .logo {
        width: 14vw;
        height: 14vw;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.divider {
    border-top: 1px dotted var(--lightBlueAlpha);
}