.rectangle2 {
    height: 5.85vh;
    background-color: var(--cardHeader);
}

.loading {
    height: 5vh;
    background-color: var(--cardHeader);
}

@media (max-width: 480px) {
    .rectangle2 {
        height: 6.5vh;
        background-color: var(--cardHeader);
    }
}