.container {
    min-height: 100%;
    line-height: 4vh !important;
    font-size: 0.75vw !important;
}

.noteBox {
    border: 2px solid var(--lightBlue);
}

.noteBox:hover {
    background-color: var(--lightBlue);
}


.loading {
    height: 5.2vh;
    background-color: var(--cardHeader);
    border-radius: 100px;
}

@media (max-width: 480px) {

    .container {
        font-size: 2.9vw !important;
        line-height: 3.6vh !important;
    }

}