@font-face {
  font-family: 'polban-icon';
  src: url('../font/polban-icon.eot?92518193');
  src: url('../font/polban-icon.eot?92518193#iefix') format('embedded-opentype'),
       url('../font/polban-icon.woff2?92518193') format('woff2'),
       url('../font/polban-icon.woff?92518193') format('woff'),
       url('../font/polban-icon.ttf?92518193') format('truetype'),
       url('../font/polban-icon.svg?92518193#polban-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'polban-icon';
    src: url('../font/polban-icon.svg?92518193#polban-icon') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "polban-icon";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-transaction:before { content: '\0100'; } /* 'Ā' */
.icon-exchange-1:before { content: '\0101'; } /* 'ā' */
.icon-transfer:before { content: '\0102'; } /* 'Ă' */
.icon-up-1:before { content: '\0103'; } /* 'ă' */
.icon-down-1:before { content: '\0104'; } /* 'Ą' */
.icon-exchange-arrow:before { content: '\0105'; } /* 'ą' */
.icon-menu:before { content: '\0106'; } /* 'Ć' */
.icon-share:before { content: '\0107'; } /* 'ć' */
.icon-download:before { content: '\0108'; } /* 'Ĉ' */
.icon-chat:before { content: '\0109'; } /* 'ĉ' */
.icon-down-open:before { content: '\0110'; } /* 'Đ' */
.icon-left-open:before { content: '\0111'; } /* 'đ' */
.icon-right-open:before { content: '\0112'; } /* 'Ē' */
.icon-up-open:before { content: '\0113'; } /* 'ē' */
.icon-up-open-1:before { content: '\0114'; } /* 'Ĕ' */
.icon-right-open-1:before { content: '\0115'; } /* 'ĕ' */
.icon-left-open-1:before { content: '\0116'; } /* 'Ė' */
.icon-down-open-1:before { content: '\0117'; } /* 'ė' */
.icon-eye-off:before { content: '\0118'; } /* 'Ę' */
.icon-eye:before { content: '\0119'; } /* 'ę' */
.icon-copy:before { content: '\0120'; } /* 'Ġ' */
.icon-send:before { content: '\0121'; } /* 'ġ' */
.icon-attach:before { content: '\0122'; } /* 'Ģ' */
.icon-cancel-circled:before { content: '\0123'; } /* 'ģ' */
.icon-cancel:before { content: '\0124'; } /* 'Ĥ' */
.icon-info-circled:before { content: '\0125'; } /* 'ĥ' */
.icon-paste-1:before { content: '\0126'; } /* 'Ħ' */
.icon-share-1:before { content: '\0127'; } /* 'ħ' */
.icon-ok:before { content: '\0128'; } /* 'Ĩ' */
.icon-deposit:before { content: '\0129'; } /* 'ĩ' */
.icon-exchange:before { content: '\0130'; } /* 'İ' */
.icon-withdrawal:before { content: '\0131'; } /* 'ı' */
.icon-email:before { content: '\0132'; } /* 'Ĳ' */
.icon-location:before { content: '\0133'; } /* 'ĳ' */
.icon-mobile:before { content: '\0134'; } /* 'Ĵ' */
.icon-phone-1:before { content: '\0135'; } /* 'ĵ' */
.icon-postalcode:before { content: '\0136'; } /* 'Ķ' */
.icon-icons8-external-link:before { content: '\0137'; } /* 'ķ' */
.icon-ok-circle:before { content: '\0138'; } /* 'ĸ' */
.icon-resize-full:before { content: '\0139'; } /* 'Ĺ' */
.icon-gift:before { content: '\0140'; } /* 'ŀ' */
