.container {
    min-height: 100%;
}

.noteBox {
    background-color: var(--lightBlue);
}

.documentBox {
   /* border-right: 2px solid #E0E0E0;
    padding: 1.5vh 1vw;*/
}

.loading {
    height: 6vh;
    background-color: var(--cardHeader);
}

.image {
    width: 15%;
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}


@media (max-width: 480px) {
    /*.documentBox {
        padding: 1.5vh 4vw;
    }*/
}
